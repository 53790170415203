import logo from './bTanium_logo.svg';
import './App.css';
import CookieConsent from "react-cookie-consent";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <br></br>
        <br></br>
        <gen-search-widget configId="1ee7106b-e9e8-4667-b140-ad7e51346e34" triggerId="searchWidgetTrigger"> </gen-search-widget>
        <form className="input">
          <input
            type="text"
            placeholder="Enter your prompt"
            id="searchWidgetTrigger"
            className="input__box"
          />
          <button type="submit" className="input_submit">
            GO
          </button>
        </form>
      </header>
      <footer className="App-footer">
        <p>bTanium demo <br></br> © 2023 Ceylon Precious Ltd.
          <a href="mailto:info@btanium.com" className="footer__a"> info@btanium.com</a></p>
      </footer>
      <CookieConsent
        buttonText="ACCEPT"

        style={{
          background: "linear-gradient(to right, rgb(77, 118, 184), rgb(22, 3, 84))",
          textShadow: "2px 2px black",
        }}
        buttonStyle={{
          background: "#092c72",
          color: "white",
          fontWeight: "bolder",
          textShadow: "2px 2px black",
        }}
      >
        This website uses cookies to enhance the user experience.{" "}
        <span style={{ fontSize: "10px" }}></span>
      </CookieConsent>
    </div>
  );
}

export default App;
